import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Form, Input, message, Modal, Spin } from "antd";
import SystemPage from "@routes/report/system/system";
import { ROUTE_REPORT_SYSTEM } from "@src/constants";
import { withRouter } from "react-router-dom";
import api from "@services/api";

class BuyProductByHand extends Component {
  formRef = React.createRef()
  state = {
    modalLoading: false,
  }

  handleSubmit = async () => {
    this.formRef.current.validateFields().then(async value => {
      this.setState({modalLoading: true})

      const { status, data } = await api.buyProductForUserByEmail({
        email: value.email,
        product_id: value.productId,
        is_marketing: value.marketing
      })

      if (status === 200 && data?.status === 0) {
        message.success(data?.message)
        this.formRef.current.resetFields()
      } else {
        message.warning(data?.message ?? 'Ошибка сервера')
      }

      this.setState({modalLoading: false})
    })
  }

  render() {
    const { history } = this.props
    const { modalLoading } = this.state

    return (
      <>
        <SystemPage />
        <Modal
          open={true}
          onCancel={() => history.push(ROUTE_REPORT_SYSTEM)}
          footer={(<div className='flex justify-center'><Button onClick={() => this.handleSubmit()}>Отправить</Button></div>)}
        >
          <Spin spinning={modalLoading}>
            <Form layout={'vertical'} ref={this.formRef} validateTrigger={['onBlur', 'onChange']}>
              <div className='flex gap-10'>
                <Form.Item
                  name='email'
                  label='Email'
                  rules={[
                    {required: true, message: 'Необходимо заполнить это поле'},
                    {type: 'email', message: 'Неверно введен email'}
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <Form.Item
                name='productId'
                label='ID Продукта'
                rules={[
                  {required: true, message: 'Необходимо заполнить это поле'}
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name='marketing' valuePropName="checked">
                <Checkbox>Распределить по маркетингу</Checkbox>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      BuyProductByHand
    )
  )
);
