import CatalogCard from '@common/catalogCard';
import CatalogHeader from '@common/catalogHeader';
import CatalogProjectCard from '@common/catalogProjectCard';
import MobileHeader from '@common/mobileHeader';
import BuyProduct from '@common/drawer/buyProduct';
import api from '@services/api';
import { PRODUCT_TYPE_PRODUCT, ROUTE_PROJECTS, ROUTE_BULLRAN_INDEX } from '@src/constants';
import { getMessageFromRequest } from '@src/helpers';
import { setDrawerContent } from '@state/drawer';
import store from '@state/store';
import { updateWalletById } from '@state/user';
import { Spin, message } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import "./styles.less";

class ProductsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      products: null,
      projects: null,
      productParams: null,
      currencyRate: null,
    }
  }

  componentDidMount = async () => {
    const {status, data} = await api.getProducts();

    if (status === 200 && data?.status === 0) {
      // Преобразования данных в нужный формат
      const productParams = [];
      data?.allProductParams?.forEach((x) => {
        if (!productParams[x.product_id]) {
          productParams[x.product_id] = [];
        }
        productParams[x.product_id].push(x);
      });
      this.setState({
        products: data?.products || [],
        projects: data?.projects || [],
        productParams,
        currencyRate: data.currencyRate,
      })
    }
    this.setState({
      loading: false,
    })
  }

  handleProductBuy = async (product) => {
    const { wallets, updateUserWalletById, updateDrawerContent } = this.props;
    const currentWallet = wallets?.find((x) => Number(x.currency_id) === Number(product.currency_id));

    if (!currentWallet || !currentWallet.id) {
      message.error('К сожалению, не найден кошелек для покупки продукта. Попробуйте повторить операцию позднее', 4);
      return;
    }

    this.setState({ loading: true });

    const {status, data} = await api.getWalletById(currentWallet?.id)

    this.setState({ loading: false });

    if (status === 200 && [0, 2000].includes(data?.status)) {
      if (
        (data?.delta && Number(data?.delta) !== 0)
        || (data?.wallet.balance && Number(data?.wallet?.balance) !== Number(currentWallet?.balance))
      ) {
        store.dispatch(updateUserWalletById(data.wallet));
      }
      if (data?.wallet?.balance && Number(data?.wallet?.balance) < Number(product.current_price_with_sale)) {
        store.dispatch(
          updateDrawerContent({
            content: BuyProduct,
            params: {
              product: product,
              wallet: data?.wallet,
            },
          })
        );
      } else {
        this.setState({loading: true});
        const {status, data} = product.type === PRODUCT_TYPE_PRODUCT
          ? await api.buyProduct(product.id, currentWallet?.id)
          : await api.upgradeProduct(product.id, currentWallet?.id);
        if (status === 200 && data?.status === 0 && data?.wallet?.id) {
          message.success("Поздравляем, покупка прошла успешно");
          store.dispatch(updateUserWalletById(data?.wallet));
          await api.updateUserExtraInfo();
          const {status: productsStatus, data: productsData} = await api.getProducts();

          if (productsStatus === 200 && productsData?.status === 0) {
            this.setState({
              products: productsData?.products || [],
            });
          }
        } else {
          message.error(getMessageFromRequest(data));
        }
        this.setState({loading: false});
      }
    } else {
      message.error('К сожалению, сервис проверки баланса недоступен. Попробуйте повторить операцию позднее', 4);
    }
  }

  handleProjectBuy = async (project) => {
    const { wallets, updateUserWalletById, history } = this.props;
    const currentWallet = wallets?.find((x) => Number(x.currency_id) === Number(project.currency_id));

    if (!currentWallet || !currentWallet.id) {
      message.error('К сожалению, не найден кошелек для покупки продукта. Попробуйте повторить операцию позднее', 4);
      return;
    }

    this.setState({ loading: true });

    const {status, data} = await api.getWalletById(currentWallet?.id)

    this.setState({ loading: false });

    if (status === 200 && [0, 2000].includes(data?.status)) {
      if (
        (data?.delta && Number(data?.delta) !== 0)
        || (data?.wallet.balance && Number(data?.wallet?.balance) !== Number(currentWallet?.balance))
      ) {
        store.dispatch(updateUserWalletById(data.wallet));
      }
      if (project?.name.toLowerCase().includes('soulmate') || project?.name.toLowerCase().includes('bullran')) {
        history.push(ROUTE_BULLRAN_INDEX.replace(':project_id', project?.id));
      } else {
        history.push(`${ROUTE_PROJECTS}/${project?.id}`)
      }
    } else {
      message.error('К сожалению, сервис проверки баланса недоступен. Попробуйте повторить операцию позднее', 4);
    }
  }

  render() {
    const { wallets } = this.props;
    const { productParams, products, projects, loading } = this.state;

    if (!wallets || !wallets?.length) {
      return null;
    }

    return (
      <main>
        <Spin spinning={loading}>
          <div className="wrap arsenal_products">
            <MobileHeader />
            <CatalogHeader pageName='Арсенал SM' withPaySubscription={false}/>
            {
              products?.length > 0 && products
                .filter((x) => !x.disabled)
                .map((x) => (
                  <CatalogCard
                    key={x.name}
                    product={x}
                    productParams={productParams[x.id]}
                    handleBuy={this.handleProductBuy}
                  />
                ))
            }
            {
              projects?.length > 0 && projects
                .filter((x) => !x.disabled)
                .map((x) => (
                  <CatalogProjectCard
                    key={x.name}
                    project={x}
                    handleProjectBuy={this.handleProjectBuy}
                  />
                ))
            }
          </div>
        </Spin>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  wallets: state.user?.info?.wallets,
  drawer: state.drawer,
});

const mapDispatchToProps = {
  updateUserWalletById: updateWalletById,
  updateDrawerContent: setDrawerContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    ProductsPage
  )
);
