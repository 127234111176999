import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Form, Input, InputNumber, message, Modal, Spin } from "antd";
import SystemPage from "@routes/report/system/system";
import { ROUTE_REPORT_SYSTEM } from "@src/constants";
import { withRouter } from "react-router-dom";
import api from "@services/api";

class PayForServers extends Component {
  formRef = React.createRef()
  state = {
    modalLoading: false,
  }

  handleSubmit = async () => {
    this.formRef.current.validateFields().then(async value => {
      this.setState({modalLoading: true})

      const { status, data } = await api.payForServers({
        amount_USDT: value.amountUSDT,
        link_to_transaction: value.linkToTransaction
      })

      if (status === 200 && data?.status === 0) {
        message.success(data?.message)
        this.formRef.current.resetFields()
      } else {
        message.warning(data?.message ?? 'Ошибка сервера')
      }

      this.setState({modalLoading: false})
    })
  }

  render() {
    const { history } = this.props
    const { modalLoading } = this.state

    return (
      <>
        <SystemPage />
        <Modal
          open={true}
          onCancel={() => history.push(ROUTE_REPORT_SYSTEM)}
          footer={(<div className='flex justify-center'><Button onClick={() => this.handleSubmit()}>Отправить</Button></div>)}
        >
          <Spin spinning={modalLoading}>
            <Form layout={'vertical'} ref={this.formRef} validateTrigger={['onBlur', 'onChange']}>
              <div className='flex gap-10'>
                <Form.Item
                  name='amountUSDT'
                  label='Количество USDT'
                  help={'Сумма оплаты в USDT'}
                  rules={[
                    {required: true, message: 'Необходмо заполнить это поле'}
                  ]}
                >
                  <InputNumber min={0}></InputNumber>
                </Form.Item>
              </div>
              <Form.Item
                name='linkToTransaction'
                label='Ссылка на транзакцию'
                help={'Ссылка на транзакцию в сети TRX-20'}
                rules={[
                  {required: true, message: 'Необходмо заполнить это поле'}
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      PayForServers
    )
  )
);