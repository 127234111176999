import React, { Component } from "react";
import { message, Modal, Table } from "antd";
import api from "@services/api";
import { NumericFormat } from "react-number-format";
import { DECIMAL_SCALE } from "@src/constants";

class ProductList extends Component {
  state = {
    isModalOpen: false,
    modalLoading: false,
    modalData: {
      products: [],
      singleProducts: [],
    }
  }

  openModal = async (userId) => {
    this.setState({
      isModalOpen: true,
      modalLoading: true,
    })

    const { status, data } = await api.getReportUserProductList({user_id: userId});

    if (status === 200 && data?.status === 0) {
      this.setState({
        modalData: {
          products: data?.data?.products,
          singleProducts: data?.data?.single_products
        }
      })
    } else {
      message.warning(data?.message ?? 'Ошибка сервера')
    }

    this.setState({
      modalLoading: false,
    })
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modalLoading: false,
      modalData: {
        products: [],
        singleProducts: [],
      }
    })
  }

  productsColumns = [
    {
      title: 'Дата покупки',
      dataIndex: 'date_buy',
      key: 'date_buy',
      render: (date_buy) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Дата покупки:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{date_buy}</div>
        </div>
      )
    },
    {
      title: 'ID Продукта',
      dataIndex: 'product_id',
      key: 'product_id',
      render: (product_id) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">ID Продукта:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{product_id}</div>
        </div>
      )
    },
    {
      title: 'Название продукта',
      dataIndex: 'product_name',
      key: 'product_name',
      render: (product_name) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Название продукта:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{product_name}</div>
        </div>
      )
    },
    {
      title: 'Апгрейд',
      dataIndex: 'upgrade',
      key: 'upgrade',
      render: (upgrade) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Апгрейд:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{upgrade ? 'Да' : 'Нет'}</div>
        </div>
      )
    },
  ]

  singleProductColumns = [
    {
      title: 'Дата покупки',
      dataIndex: 'date_buy',
      key: 'date_buy',
      render: (date_buy) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Дата покупки:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{date_buy}</div>
        </div>
      )
    },
    {
      title: 'Название продукта',
      dataIndex: 'product_name',
      key: 'product_name',
      render: (product_name) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Название продукта:</div>
          <div
            className="column-content-block st-table-imfo type column_type">{product_name}</div>
        </div>
      )
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      key: 'sum',
      render: (sum, record) => (
        <div className="flex flex-row gap-1 column-row-block">
          <div className="block lg:hidden column-title-block">Сумма:</div>
          <div
            className="column-content-block st-table-imfo type column_type">
            <NumericFormat
            value={sum || 0}
            displayType={'text'}
            decimalScale={DECIMAL_SCALE}
            thousandSeparator=" "
          />&nbsp;{record.currency}</div>
        </div>
      )
    },
  ]

  render() {
    const { isModalOpen, modalLoading, modalData } = this.state

    const { id, loading } = this.props

    return (
      <>
        <button onClick={() => this.openModal(id)} disabled={loading}>
          Список покупок
        </button>
        <Modal
          open={isModalOpen}
          footer={false}
          width={'max-content'}
          destroyOnClose={true}
          onCancel={this.closeModal}
        >
          <Table
            title={() => ('Покупки/апгрейды пакетов')}
            className="m-2"
            dataSource={ modalData?.products }
            loading={ modalLoading }
            scroll={{x: 'max-content'}}
            pagination={ false }
            columns={ this.productsColumns }
          />

          <Table
            title={() => ('Единичные покупки')}
            className="m-2"
            dataSource={ modalData?.singleProducts }
            loading={ modalLoading }
            scroll={{x: 'max-content'}}
            pagination={ false }
            columns={ this.singleProductColumns }
          />

        </Modal>
      </>
    )
  }
}

export default ProductList