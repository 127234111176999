import Avatar from '@common/avatar';
import MessagePanel from '@common/messagePanel/messagePanel';
import SubscriptionTimer from '@common/subscriptionTimer/subscriptionTimer';
import { ROLE_BEGINNER, SUBSCRIPTION_MAX_DAYS_VIEW } from '@src/constants';
import { subscriptionTimer } from '@src/helpers';
import React, { Component } from 'react';

import "./styles.less"
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class Hello extends Component {
  render() {
    const { userInfo } = this.props;

    if (!userInfo || userInfo.id === null || userInfo.id === undefined) {
      return null;
    }

    const days_left_view = subscriptionTimer(userInfo);
    const hideSubscription = userInfo.role_id === ROLE_BEGINNER || days_left_view > SUBSCRIPTION_MAX_DAYS_VIEW;

    return (
      <div className="hello">
        <div className="sidebar-user hide">
          <div className="sidebar-user-photo">
            <Avatar size={110} />
          </div>
        </div>
        <div className="hello-name">
          Добро пожаловать, <br />
          {userInfo.first_name}
        </div>

        <div className="hello-right">
          {hideSubscription
            ? <MessagePanel isHorizontal={true} hidden={true} />
            : <SubscriptionTimer isHorizontal={true} withInfoCard={false} />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Hello
  )
);
