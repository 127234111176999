import { referralsLink } from '@src/helpers';
import { message } from 'antd';
import copy from 'copy-to-clipboard';
import React, { Component } from 'react';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';

import { ReactComponent as CopyToClipboardIcon } from '@ui/icons/copyToClipboard.svg';

class ReferralsLinkTextBlock extends Component {
  handleCopy = (text) => {
    copy(text.trim());
    message.success('Скопировано', 1);
  }

  render() {
    const { userInfo, qrcode } = this.props;

    const qr =  qrcode ?? true;
    const link = referralsLink(userInfo.tg_id);

    return link === null
      ? (
        <>
          Для того, чтобы получить реферальную ссылку вы должны авторизоваться через Telegram
          (сейчас нужно Выйти, на Главной странице нажать "Вход", далее - выбрать синюю кнопку вверху формы "Авторизация")
        </>
      )
      : (
        <>
          <div className="info__code-stroke">
            <input type="text" value={link} disabled className="!w-full" />
            <div className="info__copy">
              <CopyToClipboardIcon onClick={() => this.handleCopy(link)}/>
            </div>
          </div>
          {qr ? (
            <>
              <p>
                Приглашайте людей, чтобы получать
                &nbsp;
                <br className="md-hide" />
                дополнительные выплаты
              </p>
              <br />
              <p>
                QR-код для реферальной ссылки:
                <div>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "320px", width: "100%" }}
                    className="mt-2"
                    value={link}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </p>
            </>
          ) : false}
        </>
      );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  ReferralsLinkTextBlock
);
