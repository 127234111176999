import CatalogHeader from '@common/catalogHeader';
import MediaPlayer from '@common/mediaPlayer';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import showTimePeriod from '@src/helpers/showTimePeriod';
import showTimeReminder from '@src/helpers/showTimeReminder';
import { Collapse, Table, Tabs } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

import { ReactComponent as PlayIcon } from '@ui/icons/play.svg';

import "./styles.less";

const { Panel } = Collapse;

const emptyPlayerState = {
  showPlayer : false,
  url: '',
  title: '',
  isAudio: false,
  playing: true,
  hls: false,
}

class LeaderForMillionPage extends Component {
  state = {
    showTable: true,
    // showTable: false,
    transactions: [],
    loading: false,
    beginDate: undefined,
    endDate: undefined,
    timeZone: undefined,
    deltaDays: undefined,
    deltaHours: undefined,
    deltaMinutes: undefined,
    timer: undefined,
    ...emptyPlayerState,
  }

  componentDidMount() {
    const { showTable } = this.state;

    if (showTable) {
      this.getTransactionList();

      const timer = setInterval(() => this.getTimer(), 1000 * 60);
      this.setState({ timer });
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  getTransactionList = async () => {
    this.setState({loading: true});

    const { status, data } = await api.getLeaderForMillion();

    if (status === 200 && data.status === 0) {
      this.setState({
        transactions: data?.list?.length
          ? data.list.filter((x) => Number(x.sav) > 0)
          : [],
        beginDate: data.date_begin ? new dayjs(data.date_begin, 'YYYY-MM-DD') : undefined,
        endDate: data.date_end ? new dayjs(data.date_end, 'YYYY-MM-DD') : undefined,
        timeZone: data.time_zone,
      }, () => {
        this.getTimer();
      });
    } else {
      this.setState({showTable: false});
    }

    this.setState({loading: false});
  }

  handleClose = () => {
    this.setState({
      ...emptyPlayerState,
      playing: false,
    });
  }

  getTabs = (transactions, loading, columns, userInfo, showTable) => [
    {
      key: 'current',
      label: showTable
        ? (<div>Набор в новую группу</div>)
        : (<div>Набор в новую группу (пока закрыт)</div>),
      children: (
        <>
          <h2 className="pb-1 text-lg">Список участников:</h2>
          {
            showTable
              ? (
                <Table
                  sticky={true}
                  pagination={false}
                  tableLayout={'auto'}
                  dataSource={transactions}
                  columns={columns}
                  loading={loading}
                  rowKey={(record) => record.user_id}
                  rowClassName={(record) => classNames(
                    "st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0",
                    Number(record.spv || 0) < 12
                      ? 'red'
                      : (
                        Number(record.sav || 0) < 20
                          ? 'yellow'
                          : 'green'
                      ),
                    (Number(record.user_id || -1) === Number(userInfo?.id || 0)) && 'current-user'
                  )}
                  className='static-table'
                />
              )
              : <p>Пока нет набора на данный тренинг</p>
          }
        </>
      ),
    },
    {
      key: 'group1',
      label: (<div>Группа 1</div>),
      children: (
        <ul>
          <li>
            <b>Даты проведения тренинга:</b>
            <ul>
              <li>14 - 17 мая 2024.</li>
            </ul>
          </li>
          <br />
          <li>
            <b>Тренерский состав Корпоративного университета SoulMate:</b>
            <ol>
              <li>Виталий Пятенко</li>
              <li>Максим Бумарсков</li>
              <li>Дмитрий Петров</li>
            </ol>
          </li>
          <br />
          {/*<li>
            <b>Утвержденный состав наблюдателей Корпоративного университета SoulMate:</b>
            <ol>
              <li>ID 4 - Еремин Максим - <br className="xs-show" />Сертификат: БС I	№ 0000002</li>
              <li>ID 6 - Светлана Терещенко - <br className="xs-show" />Сертификат: БС I № 0000001</li>
              <li>ID 23 - Олег Кондратов. - <br className="xs-show" />Сертификат: БС I № 0000004</li>
            </ol>
          </li>
          <br />*/}
          <li>
            <b>Утвержденный состав слушателей Корпоративного университета SoulMate:</b>
            <ol>
              <li>ID 4 - Еремин Максим<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 6 - Терещенко Светлана<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 7 - Климов Василий<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 10 - Романов Дмитрий<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 17 - Палагин Евгений<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 23 - Кондратов Олег<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 28 - Новожилов Анатолий<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 29 - Балвакова Светлана<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 43 - Коковин Андрей<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 108 - Шиканов Роман<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 341 - Никулин Денис<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 365 - Шахова Елена<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 476 - Архинчеева Ольга<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 490 - Еремина Оксана<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 512 - Ролдугин Юрий<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 909 - Зеленин Сергей<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 1044 - Малышкина Екатерина<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 1194 - Семенов Алексей<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 1709 - Кирпичникова Татьяна<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 1888 - Лемасова Ольга<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 2211 - Панфилова Юля<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 2518 - Клеванский Александр<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 2616 - Ванян Вардан<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
              <li>ID 4144 - Шелестов Николай<br className="xs-show" /><span className="hidden"> - Сертификат: __ __ № 00000__</span></li>
            </ol>
          </li>
        </ul>
      ),
    },
  ];

  getTimer = () => {
    const { loading, endDate } = this.state;

    if (endDate) {
      const today = new dayjs();

      if (today <= endDate) {
        const deltaDays = endDate.diff(today, 'days');
        const deltaHours = endDate.add((-1) * deltaDays, 'days').diff(today, 'hours');
        const deltaMinutes = endDate.add((-1) * deltaDays, 'days').add((-1) * deltaHours, 'hours').diff(today, 'minutes');
        this.setState({
          deltaDays,
          deltaHours,
          deltaMinutes,
        })
      } else if (!loading) {
        this.clearTimer();
      }
    }
  }

  clearTimer = () => {
    const { loading, timer } = this.state;

    if (timer && !loading) {
      clearInterval(timer);
      this.setState({
        deltaDays: undefined,
        deltaHours: undefined,
        deltaMinutes: undefined,
        timer: undefined,
      });
    }
  }

  render() {
    const { t, userInfo } = this.props;
    const {
      showTable,
      transactions, loading, showPlayer, url, title, isAudio, hls,
      deltaDays, deltaHours, deltaMinutes, beginDate, endDate, timeZone
    } = this.state;

    const columns = [
      {
        title: '№ п/п',
        dataIndex: 'key',
        className: 'column column_rowNum',
        render: (data, record, idx) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">№ п/п:</div>
            <div className="column-content-block">{idx + 1}</div>
          </div>
        )
      },
      {
        title: 'Сумма личных единиц',
        dataIndex: 'spv',
        key: 'spv',
        className: 'column column_spv',
        render: (sum) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Сумма личных единиц:</div>
            <div className="column-content-block">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={1}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Сумма общих единиц',
        dataIndex: 'sav',
        key: 'sav',
        className: 'column column_sav',
        render: (sum) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Сумма общих единиц:</div>
            <div className="column-content-block">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={1}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      {
        title: 'ID пользователя',
        dataIndex: 'user_id',
        key: 'user_id',
        className: 'column column_userId',
        render: (user_id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID пользователя:</div>
            <div className="column-content-block">{user_id}</div>
          </div>
        ),
      },
      {
        title: 'ФИО',
        dataIndex: 'last_name',
        key: 'last_name',
        className: 'column',
        render: (_, record) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div className="column-content-block hidden lg:block">
              {record.last_name} {record.first_name}
            </div>
            <div className="column-content-block block lg:hidden">
              {record.last_name} {record.first_name}<br/>
              (ID: {record.user_id})
            </div>
          </div>
        ),
      },
    ];

    const items = this.getTabs(transactions, loading, columns, userInfo, showTable);

    const videoUrl = undefined;
    const poster = undefined;

    return (
      <main className="university-subpage leader-for-million-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader
            pageName='Курс "Лидер на миллион"'
            parentPageName='Корпоративный университет'
            parentPageLink='/university'
            withPaySubscription={false}
          />
          {videoUrl && (
            <div className="info__card">
              <div className="info-item__video">
                <div className="fac__less bas__less">
                  {poster && (<img src={poster} alt="" className="info__video-bg" />)}
                  <div className="info__video--a">
                    <div className="info-video__text">&nbsp;</div>
                    <div
                      className="info__play"
                      onClick={() => this.setState({
                        showPlayer: true,
                        playing: true,
                        title: 'Курс "Лидер на миллион"',
                        isAudio: false,
                        url: videoUrl,
                        hls: true,
                      })}
                    >
                      <PlayIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="info__card description">
            <h2 className="pb-1 text-lg">Описание:</h2>
            <p>
              Лидерское обучение в Корпоративном университете для Амбассадоров от 4 ступени.<br />
              Обучение доступно далеко не всем.<br />
              Обучение направлено на рост и масштабирование вашего бизнеса и формирование необходимых для этого навыков предпринимателя WEB3.<br />
              Мы не только будем учиться и трудиться, мы создадим переворот вашего сознания!<br />
              Мы расширим вашу финансовую ёмкость так, чтобы она смогла вместить далеко не один миллион долларов.<br />
              Мы создадим атмосферу безусловного роста и движения к успеху.<br />
              Мы изменим ваше восприятие так, что преграды, которые сейчас видятся непреодолимыми станут ступеньками к вашим мечтам.<br />
              Это не тренинг - это перепрошивка, перезагрузка сознания таким образом, чтобы вы стали успешным, счастливым и гармоничным цифровым предпринимателем WEB 3.
            </p>

            <p><strong>В ходе обучения партнёры освоят:</strong>
              <ul className="list-disc pl-6">
                <li>Техники продаж бизнеса;</li>
                <li>Навыки командообразования;</li>
                <li>Навыки планирования и анализа;</li>
                <li>Навыки построения эффективных команд;</li>
                <li>Навыки мотивации и управления;</li>
                <li>Основы менеджмента и самоменеджмента;</li>
                <li>Основы работы через социальные сети;</li>
                <li>Навыки работы с командами через мероприятия онлайн и оффлайн;</li>
                <li>Технологию быстрого и качественного карьерного роста.</li>
              </ul>
            </p>

            <p><strong>По результатам обучения смогут:</strong>
              <ul className="list-disc pl-6">
                <li>Оптимизировать работу со своими командами;</li>
                <li>Повысить эффективность продаж и построения бизнеса;</li>
                <li>Видеть и формировать лидерские качества у себя и своих партнеров;</li>
                <li>Эффективно планировать свой бизнес, доход, время, карьеру и двигаться по пути к реализации своих самых смелых мечт более лёгким и приятным путем;</li>
                <li>Создавать гораздо больший результат в одну единицу времени;</li>
                <li>Справляться со всеми внутренними преградами и страхами, мешающими росту своего бизнеса;</li>
                <li>Помогать своим партнёрам преодолевать страхи и трудности и сохранять мотивацию.</li>
              </ul>
            </p>

            <p>Высочайший уровень менторов, спикеров и кураторов, которые искренне заинтересованы в вашем успехе. Никакой воды - только практика и только реальные бизнес-инструменты.</p>

            <p><strong>Условия</strong> прохождения:
              <ul className="list-disc pl-6">
                <li>Подтвердить квалификацию 4 ступени;</li>
                <li>В квалификационный период выполнить условия по товарообороту.</li>
                <li>Cамостоятельно прибыть к месту проведения обучения</li>
              </ul>
            </p>
            <p>
              Обучение будет проходить в комфортабельном отеле.<br/>
              Все расходы прибывших партнеров по проживанию, питанию и обучению на себя берет компания.<br/>
              Компания также оплачивает стоимость проезда к месту обучения по базовому тарифу.<br />
              Участие в данном тренинге, станет одним из наиболее ярких впечатлений в жизни каждого.<br/>
              Насыщенные 3 дня, которые эквивалентны нескольким месяцам обучения в ВУЗе.
            </p>
          </div>

          <div className="info__card">
            <h2 className="pb-1 text-lg">Условия зачисления на тренинг в новую группу:</h2>
            <ul className="list-disc pl-6">
              <li><strong>Кто может участвовать:</strong> Резиденты The SoulMate Ambassador's Club от 4-й ступени.</li>
              <li><strong>Время действия:</strong> {showTimePeriod(loading, beginDate, endDate, timeZone)}</li>
              <li><strong>До окончания осталось:</strong> {showTimeReminder(t, loading, endDate, deltaDays, deltaHours, deltaMinutes)}</li>
              <li>
                <strong>Условия зачисления:</strong>
                <ul className="list-disc pl-6">
                  <li>подтвердить квалификацию 4-ой ступени</li>
                  <li>сделать минимум 12 личных единиц товарооборота</li>
                  <li>сделать минимум 20 общих единиц товарооборота (личные + сетевые)</li>
                </ul>
              </li>
              <li><strong>Количество студентов:</strong> 20 человек.</li>
            </ul>
          </div>
          <div className="info__card">
            <div className="static__cont">
              <Tabs
                defaultActiveKey='current'
                items={items}
                className='md-hide 2xl:md-hide'
              />
              <Collapse
                accordion
                defaultActiveKey='current'
                // items={items}
                className='md-show 2xl:md-hide'
              >
                {items.map((x) => (
                  <Panel key={x.key} header={x.label}>
                    { x.children }
                  </Panel>
                ))}
              </Collapse>
            </div>
          </div>
          {videoUrl && (
            <MediaPlayer showPlayer={showPlayer} title={title} url={url} handleClose={this.handleClose} isAudio={isAudio} hls={hls} />
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    LeaderForMillionPage
  )
);
