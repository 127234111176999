import { handleMoveToElement, openInNewTab } from '@components/landing/utils';
import { show_form_user_registration } from '@src/config';
import {
  FORM_LOGIN,
  FORM_REGISTRATION,
  SOULMATE_ECOSYSTEM,
  TELEGRAM_FOR_MAIN,
} from '@src/constants';
import { closeBurger } from '@state/burger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import { ReactComponent as SoulMateLogoFooterIcon } from '@components/landing/icons/soulMateLogoFooter.svg';
import { ReactComponent as SoulMateLogoFooterIcon } from '@components/landing/icons/logo_white.svg';
// import { ReactComponent as SoulMateLogoColorIcon } from '@components/landing/icons/soulMateLogoColor.svg';
import { ReactComponent as SoulMateLogoColorIcon } from '@components/landing/icons/logo_color.svg';
import { ReactComponent as SocialNetworkTelegramIcon } from '@components/landing/icons/SocialNetworkTelegram.svg';
// import { ReactComponent as SocialNetworkTwitterIcon } from '@components/landing/icons/SocialNetworkTwitter.svg';
// import { ReactComponent as SocialNetworkFacebookIcon } from '@components/landing/icons/SocialNetworkFacebook.svg';
// import { ReactComponent as SocialNetworkInstagramIcon } from '@components/landing/icons/SocialNetworkInstagram.svg';

import './styles.less';

class LandingFooter extends Component {
  render() {
    const { burgerIsOpen, clickCloseBurger, setFormType } = this.props;

    return (
      <>
        <div className="footer">
          <div className="footer-top">
            <div className="wrap">

              <div className="footer-head">
                <div className="footer-left">
                  <div className="footer-logo">
                    <SoulMateLogoFooterIcon className="w-[110px] h-[68px]" />
                  </div>
                  <p>
                    Tech community
                  </p>
                </div>
                <div className="footer-line"></div>
                <div className="footer-right">
                  <div className="footer-menu">
                    <ul>
                      <li><button className="gta a" onClick={() => handleMoveToElement('.d5')}>Эволюционная цель</button></li>
                      <li><button className="gta a" onClick={() => handleMoveToElement('.d12')}>Клуб Амбассадоров</button></li>
                      <li><button className="gta a" onClick={() => openInNewTab(SOULMATE_ECOSYSTEM)}>WhitePaper</button></li>
                      {/*<li><Link className="gta" onClick={() => handleMoveToElement('.d17')}>Вебинар</Link></li>*/}
                    </ul>
                    {/*<div className="landing-standart-btn gta" data-anchor=".d10">
                      <div className="landing-standart-btn-text">Зарегистрироваться</div>
                      <div className="landing-standart-btn-shadow" />
                    </div>*/}
                    <div className='flex '>
                      {/*<button className='a' onClick={() => openInNewTab(TELEGRAM_FOR_MAIN)}>
                        <SocialNetworkTelegramIcon />
                      </button>*/}
                      {show_form_user_registration !== 0 && (
                        <button
                          className="header-btn gta !mr-5 !opacity-100"
                          data-anchor=".d10"
                          onClick={() => setFormType(FORM_REGISTRATION)}
                        >
                          Регистрация
                        </button>
                      )}
                      <button
                        className="header-btn gta !mr-0 !opacity-100"
                        data-anchor=".d10"
                        onClick={() => setFormType(FORM_LOGIN)}
                      >
                        Вход
                      </button>
                    </div>
                  </div>
                  <div className="footer-flex !hidden 2xl:!flex">
                    <div className="footer-docs">
                    {/*  <p>Лицензия <br />
                        на образовательную <br />
                        деятельность:</p>
                      <a href="static/imgs/footer-doc.png" data-fancybox className="footer-doc">
                        <img src={require('../assets/images/footer-doc.png')} alt="" />
                      </a>*/}
                      &nbsp;
                    </div>
                    <div className="footer-socs">
                      <button className='a' onClick={() => openInNewTab(TELEGRAM_FOR_MAIN)}>
                        <SocialNetworkTelegramIcon />
                      </button>
                      {/*<Link href="https://twitter.com/" target='_blank'>
                        <SocialNetworkTwitterIcon />
                      </Link>*/}
                      {/*<Link href="https://facebook.com/" target='_blank'>
                        <SocialNetworkFacebookIcon />
                      </Link>*/}
                      {/*<Link href="https://instagram.com/" target='_blank'>
                        <SocialNetworkInstagramIcon />
                      </Link>*/}
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-contact">
                {/*<div className="footer-item">
                  <a href="tel:+13322371807">+1-332-237-18-07</a>
                  <span>Контактный центр</span>
                </div>*/}
                {/*<div className="footer-item">
                  <a href="tel:8 (800) 999-99-99">8 (800) 999-99-99</a>
                  <span>Контактный центр</span>
                </div>*/}
                <div className="footer-item">
                  <span>
                    SOULMATE ECOSYSTEM PTE. LTD. (UEN 202347192W)<br />
                    10, ANSON ROAD, #20-05, INTERNATIONAL PLAZA, SINGAPORE (079903)
                  </span>
                </div>
                <div className="footer-item">
                  <a href="mailto:support@soulmate.partners">support@soulmate.partners</a>
                </div>
                <div className="footer-socs !justify-center">
                  <button className='a' onClick={() => openInNewTab(TELEGRAM_FOR_MAIN)}>
                    <SocialNetworkTelegramIcon />
                  </button>
                  {/*<Link href="https://twitter.com/" target='_blank'>
                    <SocialNetworkTwitterIcon />
                  </Link>*/}
                  {/*<Link href="https://facebook.com/" target='_blank'>
                    <SocialNetworkFacebookIcon />
                  </Link>*/}
                  {/*<Link href="https://instagram.com/" target='_blank'>
                    <SocialNetworkInstagramIcon />
                  </Link>*/}
                </div>
              </div>

            </div>
          </div>
          <div className="footer-center">
            <div className="wrap">
              {/*<Link to={'/files/Public_offer_agreement.pdf'} target='_blank'>Оферта</Link>*/}
              {/*<Link to={''} target='_blank'>Оплата</Link>*/}
              <Link to={'/files/Terms_of_use_the_website_Soulmate.pdf'} target='_blank'>Правила пользования Платформой</Link>
              <Link to={'/files/Privacy_policy.pdf'} target='_blank'>Политика конфиденциальности</Link>
              {/*<Link to={''} target='_blank'>Сертификаты обучения</Link>*/}
              <Link to={'/files/Use_personal_data_agreement.pdf'} target='_blank'>Персональные данные</Link>
            </div>
          </div>
          <div className="footer-policy">
            <div className="wrap">
              *SoulMate не является инвестиционной компанией и не дает инвестиционных рекомендаций.
              <br />
              &nbsp;
              Используя полученные знания вы можете начать инвестировать самостоятельно,
              не забывая при этом про собственный анализ
            </div>
          </div>
        </div>

        {burgerIsOpen && (
          <div className="burger-menu">
            <div className="header">
              <div className="wrap">
                <div className="header-logo">
                  <SoulMateLogoColorIcon className="w-[110px] h-[68px]" />
                </div>
                <button
                  type={'button'}
                  className="burger-close header-burger"
                  onClick={() => clickCloseBurger()}
                >
                  <div />
                  <div />
                </button>
              </div>
              <div className="wrap flex flex-col gap-4 !items-start user-button-block">
                {show_form_user_registration !== 0 && (
                  <button
                    className="landing-standart-btn landing-standart-btn-border"
                    onClick={() => {
                      setFormType(FORM_REGISTRATION);
                      clickCloseBurger();
                    }}
                  >
                    <div className='landing-standart-btn-text'>
                      Регистрация
                    </div>
                  </button>
                )}
                <button
                  className="landing-standart-btn landing-standart-btn-border"
                  onClick={() => {
                    setFormType(FORM_LOGIN);
                    clickCloseBurger();
                  }}
                >
                  <div className='landing-standart-btn-text'>
                    Вход
                  </div>
                </button>
              </div>
            </div>
            <div className="burger-content">
              <div className="wrap">
                <ul>
                  <li><button className="gta a" onClick={() => {handleMoveToElement('.d5'); clickCloseBurger();}}>Эволюционная цель</button></li>
                  <li><button className="gta a" onClick={() => {handleMoveToElement('.d12'); clickCloseBurger();}}>Клуб Амбассадоров</button></li>
                  <li><button className="gta a" onClick={() => {openInNewTab(SOULMATE_ECOSYSTEM); clickCloseBurger();}}>WhitePaper</button></li>
                  {/*<li><Link className="gta" onClick={() => {handleMoveToElement('.d17'); clickCloseBurger();}}>Вебинар</Link></li>*/}
                </ul>

                <div className="footer-socs">
                  <button className='a' onClick={() => openInNewTab(TELEGRAM_FOR_MAIN)}>
                    <SocialNetworkTelegramIcon />
                  </button>
                  {/*<Link href="https://twitter.com/" target='_blank'>
                    <SocialNetworkTwitterIcon />
                  </Link>*/}
                  {/*<Link href="https://facebook.com/" target='_blank'>
                    <SocialNetworkFacebookIcon />
                  </Link>*/}
                  {/*<Link href="https://instagram.com/" target='_blank'>
                    <SocialNetworkInstagramIcon />
                  </Link>*/}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

// const mapStateToProps = (state) => ({
// //   appStage: state.appStage,
// //   notificationsCounter: state.notifications.count_new,
// //   notifications: state.notifications.notificationsData,
// //   userInfo: state.user.info,
// });
//
// const mapDispatchToProps = {
//   // setAppStageData: setAppStage,
//   // clearUserInfo: setClearUserExtraInfo(),
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(
//   withTranslation()(
//     withRouter(
//       LandingHeader
//     )
//   )
// );

// export default withTranslation()(
//   withRouter(
//     TopBar
//   )
// );

const mapStateToProps = (state) => ({
//   appStage: state.appStage,
//   rights: state.rights.rightsData,
  burgerIsOpen: state.burger.isOpen,
  // userInfo: state.user.info,
});

const mapDispatchToProps = {
  clickCloseBurger: closeBurger,
}

export default connect(mapStateToProps, mapDispatchToProps) (
  LandingFooter
);
