export const PRODUCT_FILTER = [
  {'value': '0', 'label': 'Без пакета'},
  {'value': '11', 'label': 'Базовый курс'},
  {'value': '12', 'label': 'Академия'},
  {'value': '13', 'label': 'Партнер'},
  {'value': '15', 'label': 'Тест-Драйв'},
  {'value': '14', 'label': 'Амбассадор'},
  {'value': '21', 'label': 'Базовый курс (руб)'},
  {'value': '22', 'label': 'Академия (руб)'},
]

export const DEFAULT_PRODUCTS = ['14', '15'];

export const TRAINING_FILTER = [
  {'value': '0', 'label': 'Все'},
  {'value': '1', 'label': 'Новички'},
  {'value': '2', 'label': 'Переносы (заявки)'},
  {'value': '3', 'label': 'Повторники (заявки)'},
]
