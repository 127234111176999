import React, { Component } from "react";
import MobileHeader from "@common/mobileHeader";
import CatalogHeader from "@common/catalogHeader";
import {
  ROUTE_REPORT_SYSTEM_BUY_PRODUCT_BY_HAND,
  ROUTE_REPORT_SYSTEM_BUY_TRX,
  ROUTE_REPORT_SYSTEM_PAY_SERVERS,
} from "@src/constants";
import getAccessToMenuItem from "@src/helpers/getAccessToMenuItem";
import { Link } from "react-router-dom";
import getRouteText from "@src/helpers/getRouteText";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class SystemPage extends Component {
  render () {
    const { t, userInfo } = this.props;

    const reportList = [
      ROUTE_REPORT_SYSTEM_BUY_TRX,
      ROUTE_REPORT_SYSTEM_PAY_SERVERS,
      ROUTE_REPORT_SYSTEM_BUY_PRODUCT_BY_HAND,
    ]

    const userReportList = reportList
      .filter((x) => getAccessToMenuItem(x, userInfo))
      .map((x) => <li><Link to={x}>{t('LEFT_MENU_'.concat(getRouteText(x)))}</Link></li>);


    return (
      <main className="promo-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName={t('LEFT_MENU_REPORT')} withPaySubscription={false}/>
          <div className="info__card">
            <h2 className="pb-1 text-lg">Список подразделов:</h2>
            <ul className="list-disc pl-6">
              { userReportList }
            </ul>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    SystemPage
  )
);