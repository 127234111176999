import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Spin } from "antd";
import api from "@services/api";

class ChangeEmail extends Component {
  formRef = React.createRef()

  state = {
    changeEmailModalOpen: false,
    changeEmailData: undefined,
    changeEmailLoading: false,
  }

  openModal = (id) => {
    this.setState({
      changeEmailModalOpen: true,
      changeEmailData: id
    })
  }

  checkEmailDuplicate = async (email) => {
    const userId = this.state.changeEmailData

    const { status, data } = await api.checkDuplicateEmail({user_id: userId, email: email})

    if (status === 0 && data?.status === 0) {
      return Promise.resolve()
    }

    if (status === 200 && data?.status) {
      return Promise.reject(new Error(data?.message ?? 'Ощибка сервера'));
    }
  }

  changeEmail = async () => {
    const userId = this.state.changeEmailData

    this.formRef.current.validateFields().then(async (value) => {
      this.setState({changeEmailLoading: true})

      const { status, data } = await api.changeEmail({user_id: userId, email: value.email})

      if (status === 200 && data?.status === 0) {
        message.success(data?.message)
        await this.closeModal()
        await this.props.update()
      } else {
        message.warning(data?.message)
      }

      this.setState({changeEmailLoading: false})
    })
  }

  closeModal = () => {
    this.setState({
      changeEmailModalOpen: false,
      changeEmailData: undefined
    })
  }

  render() {
    const {
      changeEmailModalOpen,
      changeEmailLoading,
    } = this.state

    const { id } = this.props

    return (
      <>
        <button className='w-full text-start' onClick={() => this.openModal(id)}>
          Изменить почту
        </button>

        <Modal
          open={changeEmailModalOpen}
          footer={(<div className='flex justify-center'><Button loading={changeEmailLoading} onClick={() => this.changeEmail()}>Изменить</Button></div>)}
          onCancel={() => this.closeModal()}
          width={'max-content'}
          destroyOnClose={true}
        >
          <Spin spinning={changeEmailLoading}>
            <Form ref={this.formRef} layout='vertical'>
              <Form.Item
                validateTrigger={['onBlur', 'onChange']}
                validateFirst={true}
                name='email'
                label='Email'
                rules={[
                  {required: true, message: 'Пожалуйста ввдеите email'},
                  {type: "email", message: 'Неверно введен email'},
                  {
                    validateTrigger: 'onBlur',
                    validator: (_, value) => this.checkEmailDuplicate(value),
                    message: 'Такой email уже зарегистрирован'
                  }
                ]}>
                <Input name='email'></Input>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    )
  }
}

export default ChangeEmail