import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import React, {Component} from "react";
import {ROUTE_REPORT} from "@src/constants";
import api from "@services/api";
import MobileHeader from "@common/mobileHeader";
import CatalogHeader from "@common/catalogHeader";
import { DatePicker, Space, Table, Tooltip } from "antd";
import ExportCSV from "@common/exportCSV/component";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { QuestionCircleOutlined } from "@ant-design/icons";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class UsersSalesPage extends Component {

    state = {
        saleList: [],
        loading: false,
        dateStart: '',
        dateEnd: '',
    }

    componentDidMount() {
        this.getUsersSales()
    }

    getUsersSales = async (params = {}) => {
        this.setState({loading: true});

        let {status, data} = await api.getReportUsersSales(params);

        if (status === 200 && data?.data) {
            this.setState({
                saleList: Object.values(data?.data),
                dateStart: data.date_start ?? '',
                dateEnd: data.date_end ?? '',
            });
        }
        this.setState({loading: false});
    }

    onChange = (dates) => {
        let dateStart = dates[0] === '' ?  null : dates[0],
        dateEnd = dates[1] === '' ? null : dates[1];
        const params = {
            date_start: dateStart,
            date_end: dateEnd
        }

        this.getUsersSales(params);
    }

    render() {
        const {t} = this.props;
        let {saleList, loading} = this.state;

        const { RangePicker } = DatePicker;

        let columns = [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
                render: (name) => (
                    <div className="flex flex-row gap-1 column-row-block">
                        <div className="block lg:hidden column-title-block">Название:</div>
                        <div className="column-content-block st-table-imfo type column_type">{name}</div>
                    </div>
                ),
            },
            {
                title: 'Продажи',
                children: [
                    {
                        title: 'Действующие',
                        dataIndex: 'sales',
                        key: 'sales',
                        render: (sales) => (
                          <div className="flex flex-row gap-1 column-row-block">
                              <div className="block lg:hidden column-title-block">Действующие продажи:</div>
                              <div className="column-content-block st-table-imfo type column_type">{sales ?? 0}</div>
                          </div>
                        ),
                    },
                    {
                        title: (<span>Недействующие&nbsp;
                            <Tooltip title='Данный пакет уже не действует и вы не увидите его у
                                            пользователя (в отчете по пользователям),
                                            поскольку пользователь сделал апгрейд с него до другого пакета'>
                                <QuestionCircleOutlined style={{ color: 'rgba(140,38,251,1)' }} />
                            </Tooltip>
                        </span>),
                        dataIndex: 'sales_disabled',
                        key: 'sales_disabled',
                        render: (sales_disabled) => (
                          <div className="flex flex-row gap-1 column-row-block">
                              <div className="block lg:hidden column-title-block">Недействующие продажи:</div>
                              <div className="column-content-block st-table-imfo type column_type">{sales_disabled ?? 0}</div>
                          </div>
                        ),
                    },
                    {
                        title: 'Итого',
                        dataIndex: 'sales',
                        key: 'sales',
                        render: (sales, record) => (
                          <div className="flex flex-row gap-1 column-row-block">
                              <div className="block lg:hidden column-title-block">Итого продажи:</div>
                              <div className="column-content-block st-table-imfo type column_type">{(sales ?? 0) + (record.sales_disabled ?? 0)}</div>
                          </div>
                        ),
                    },
                ]
            },
            {
                title: 'Апгрейды',
                children: [
                    {
                        title: 'Действующие',
                        dataIndex: 'upgrade',
                        key: 'upgrade',
                        render: (upgrade) => (
                          <div className="flex flex-row gap-1 column-row-block">
                              <div className="block lg:hidden column-title-block">Действующие апгрейды:</div>
                              <div className="column-content-block st-table-imfo type column_type">{upgrade ?? 0}</div>
                          </div>
                        ),
                    },
                    {
                        title: (<span>Недействующие&nbsp;
                            <Tooltip title='Данный пакет уже не действует и вы не увидите его у
                                            пользователя (в отчете по пользователям),
                                            поскольку пользователь сделал апгрейд с него до другого пакета'>
                                <QuestionCircleOutlined style={{ color: 'rgba(140,38,251,1)' }} />
                            </Tooltip>
                        </span>),
                        dataIndex: 'upgrade_disabled',
                        key: 'upgrade_disabled',
                        render: (upgrade_disabled) => (
                          <div className="flex flex-row gap-1 column-row-block">
                              <div className="block lg:hidden column-title-block">Недействующие апгрейды:</div>
                              <div className="column-content-block st-table-imfo type column_type">{upgrade_disabled ?? 0}</div>
                          </div>
                        ),
                    },
                    {
                        title: 'Итого',
                        dataIndex: 'upgrade',
                        key: 'upgrade',
                        render: (upgrade, record) => (
                          <div className="flex flex-row gap-1 column-row-block">
                              <div className="block lg:hidden column-title-block">Итого апгрейды:</div>
                              <div className="column-content-block st-table-imfo type column_type">{(upgrade ?? 0) + (record.upgrade_disabled ?? 0)}</div>
                          </div>
                        ),
                    },
                ]
            }
        ]

        return (
            <main className="report-user-list-page">
                <div className="wrap">
                    <MobileHeader/>
                    <CatalogHeader
                        pageName={t('LEFT_MENU_REPORT/USER-SALES')}
                        parentPageName={t('LEFT_MENU_REPORT')}
                        parentPageLink={ROUTE_REPORT}
                        withPaySubscription={false}
                    />
                    <div className="flex flex-col gap-1">
                        <form className="static__header static__headerm flex flex-col gap-4">
                            <div className="static__head-block">
                                <div className="flex flex-col gap-1">
                                    <div className="flex flex-row gap-1 flex-wrap">
                                        <div className="">
                                            <Space>
                                                <RangePicker value={[dayjs(this.state.dateStart), dayjs(this.state.dateEnd)]}
                                                             onCalendarChange={(moment, dates) => {this.onChange(dates)}} />
                                            </Space>
                                        </div>
                                        <ExportCSV
                                          columns={columns}
                                          getData={()=>{return saleList}}
                                          fileName='Отчет - Продажи'
                                        >
                                          Экспорт CSV
                                        </ExportCSV>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="static__contents !py-4">
                            <div className="static__cont">
                                <Table
                                    dataSource={saleList}
                                    columns={columns}
                                    loading={loading}
                                    rowKey={(record) => record.id}
                                    rowClassName="st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0"
                                    className='static-table'
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.user.info,
    userFullInfo: state.user,
});

export default connect(mapStateToProps)(
    withTranslation()(
        withRouter(
            UsersSalesPage
        )
    )
);
