import React, { Component, createRef } from "react";
import { Button, Form, Input, message, Modal, Select, Spin } from "antd";
import InputMask from "react-input-mask";
import api from "@services/api";
import { countries } from "countries-list-es5/dist";

class ChangeUserInfo extends Component {
  formRef = createRef()

  state = {
    modalOpen: false,
    modalLoading: false,
    modalData: {
      id: undefined,
      email: undefined,
      first_name: undefined,
      last_name: undefined,
      phone: undefined,
      telegram: undefined,
      countryList: undefined,
      prefix: undefined,
    },
    userCountry: 'RU_7',
    maxPhoneLength: 12,
    userPhonePrefix: '+7',
    country: JSON.stringify(countries['RU']),
    countryKey: 'RU',
  }

  componentDidMount() {
    const {data} = this.props
    if (data?.country_key) {
      this.setUserCountry(`${data?.country_key}_${data?.prefix.replace('+', '')}`);
    }
  }

  openModal = (data) => {
    this.setState({
      modalOpen: true,
      modalData: {
        id: data?.id,
        email: data?.email,
        first_name: data?.first_name,
        last_name: data?.last_name,
        phone: data?.phone,
        telegram: data?.telegram,
        country: data?.country,
        countryKey: data?.countryKey,
        countryList: data?.countryList,
        prefix: data?.prefix,
      }
    })
  }

  closeModal = () => {
    this.formRef.current.resetFields()

    this.setState({
      modalOpen: false,
      modalData: {
        id: undefined,
        email: undefined,
        first_name: undefined,
        last_name: undefined,
        phone: undefined,
        telegram: undefined,
        country: undefined,
        countryKey: undefined,
        countryList: undefined,
        prefix: undefined,
      }
    })
  }

  setUserCountry = (val) => {
    const [key, prefix] = val.split('_');
    const currentCountry = JSON.stringify(countries[key]);
    // this.formRef?.current?.setFieldValue('phone', undefined);
    const newPhonePrefix = `+${prefix}`;
    const oldPhone = this.formRef?.current?.getFieldValue('phone');
    const oldPhoneClear = oldPhone ? oldPhone.substr(-10, 10) : oldPhone;

    this.formRef?.current?.setFieldValue('countryList', val);
    this.formRef?.current?.setFieldValue('countryKey', key);
    this.formRef?.current?.setFieldValue('country', currentCountry);
    this.formRef?.current?.setFieldValue('prefix', newPhonePrefix);
    this.formRef?.current?.setFieldValue('phone', `${newPhonePrefix}${oldPhoneClear}`);

    this.setState({
      userCountry: val,
      userPhonePrefix: newPhonePrefix,
      maxPhoneLength: prefix.length + 11,
      country: currentCountry,
      countryKey: key,
    });
  }

  checkUserDataDuplicate = async (user_id, fieldName, fieldValue) => {
    if (!fieldValue) {
      return Promise.resolve();
    }

    this.setState({
      loading: true
    })

    const {status, data} = await api.checkUserDataDuplicate({user_id, fieldName, fieldValue})

    this.setState({
      loading: false
    })

    if (status === 200 && data?.status === 0) {
      return Promise.resolve();
    }

    if (status === 200 && data?.status) {
      return Promise.reject(new Error(data?.message ?? 'Ошибка сервера'));
    }
  }

  checkEmailDuplicate = async (id, email) => {
    const { status, data } = await api.checkDuplicateEmail({user_id: id, email: email})

    if (status === 0 && data?.status === 0) {
      return Promise.resolve()
    }

    if (status === 200 && data?.status) {
      return Promise.reject(new Error(data?.message ?? 'Ощибка сервера'));
    }
  }

  handleSubmit = () => {
    this.formRef.current.validateFields().then(async values => {
      this.setState({
        modalLoading: true,
      })

      const { status, data } = await api.changeUserInfo({
        id: this.state.modalData?.id,
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone,
        telegram: values.telegram,
        country_key: values.countryKey,
        prefix: values.prefix,
        country: JSON.stringify(values.country),
        country_list: values.countryList,
      })

      if (status === 200 && data?.status === 0) {
        message.success(data?.message)
        this.props.update()
      } else {
        message?.warning(data?.message ?? 'Ошибка сервера')
      }

      this.setState({
        modalLoading: false
      })
    })
  }

  changeStatus = async (userId, block = false) => {
    this.setState({
      modalLoading: true
    })

    const { status, data } = await api.userChangeStatus({user_id: userId, status_id: block ? 10 : 99})

    if (status === 200 && data?.status === 0) {
      message.success(data?.message)
      this.props.update()
    } else {
      message.warning(data?.message ?? 'Ошибка сервера')
    }

    this.setState({
      modalLoading: false
    })
  }

  render() {
    const {
      modalOpen,
      modalData,
      modalLoading,
      maxPhoneLength,
      country,
      countryKey,
      userPhonePrefix,
      userCountry
    } = this.state
    const {data} = this.props

    const countryList = [];
    Object
      .keys(countries)
      .forEach((key) => {
        const phones = countries[key].phone.split(',');
        phones.forEach((phone) => {
          countryList.push({
            value: `${key}_${phone}`,
            label: `${countries[key].name} (Phone prefix: +${phone})`,
          });
        });
      });

    return (
      <>
        <button className='w-full text-start' onClick={() => this.openModal(data)}>
          Изменение личных данных
        </button>
        <Modal
          open={modalOpen}
          width={'max-content'}
          destroyOnClose={true}
          onCancel={this.closeModal}
          footer={(
            <div className='flex justify-between flex-wrap gap-2'>
              <Button onClick={() => this.changeStatus(data.id, data.status.id !== 10)}>
                {data.status.id > 10 ? 'Разблокировать' : 'Временно заблокировать'}
              </Button>
              <Button onClick={() => this.handleSubmit()}>Сохранить</Button>
            </div>)}
        >
          {!(data.status.id === 10 || data.status.id === 90) && (
            <p className='p-1 mb-2 w-min bg-red-500 text-white'>{data.status.name}</p>
          )}

          <Spin spinning={modalLoading}>
            <p className='p-1 mb-2 w-max'>
              ID {modalData.id}:&nbsp;
              {modalData.first_name}&nbsp;
              {modalData.last_name}&nbsp;
              ({modalData.email},&nbsp;
              {modalData.phone},&nbsp;
              {modalData.telegram})</p>

            <Form
              ref={this.formRef}
              layout='vertical'
              initialValues={{
                email: modalData.email,
                firstName: modalData.first_name,
                lastName: modalData.last_name,
                phone: modalData.phone,
                telegram: modalData.telegram,
                country: modalData.country?.length > 0 ? modalData.country : country,
                countryKey: modalData.country_key?.length > 0 ? modalData.country_key : countryKey,
                countryList: userCountry,
                prefix: modalData.prefix?.length > 0 ? modalData.prefix : userPhonePrefix,
              }}
            >
              <Form.Item name='email' label='Email'
                         validateTrigger={['onBlur', 'onChange']}
                         validateFirst={true}
                         rules={[
                           // {required: true, message: 'Пожалуйста, введите Email'},
                           {type: 'email', message: 'Неверный формат Email'},
                           {
                             validateTrigger: 'onBlur',
                             validator: (_, value) => this.checkEmailDuplicate(modalData.id, value),
                             message: 'Такой Email указан у другого пользователя'
                           }
                         ]}
              >
                <Input placeholder='name@email.net' />
              </Form.Item>
              <Form.Item name='lastName' label="Фамилия"
                         validateTrigger={['onBlur', 'onChange']}
                         validateFirst={true}
                         rules={[
                           // {required: true, message: 'Пожалуйста, введите фамилию'},
                           {max: 100, message: 'Максимальная длина поля - 100 символов'},
                           {
                             transform: (x) => x.trim(),
                             pattern: /^[a-zA-Z ]+$/,
                             message: 'В этом поле можно вводить только буквы (латиница) и пробел'
                           },
                         ]}>
                <Input placeholder='Ivanov'/>
              </Form.Item>
              <Form.Item name='firstName' label="Имя"
                         validateTrigger={['onBlur', 'onChange']}
                         validateFirst={true}
                         rules={[
                           // {required: true, message: 'Пожалуйста, введите имя'},
                           {max: 100, message: 'Максимальная длина поля - 100 символов'},
                           {
                             transform: (x) => x.trim(),
                             pattern: /^[a-zA-Z ]+$/,
                             message: 'В этом поле можно вводить только буквы (латиница) и пробел'
                           },
                         ]}>
                <Input placeholder='Ivan'/>
              </Form.Item>
              <Form.Item name='countryList' label="Страна">
                <Select
                  showSearch
                  options={countryList}
                  onSelect={this.setUserCountry}
                  getPopupContainer={(node) => node}
                  popupClassName="country-list-drop-down"
                  placeholder="Начните вводить страну на родном или Английском языке"
                  filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
              </Form.Item>
              <Form.Item name='country' hidden={true}><Input/></Form.Item>
              <Form.Item name='countryKey' hidden={true}><Input/></Form.Item>
              <Form.Item name='prefix' hidden={true}><Input/></Form.Item>
              <Form.Item name='phone' label="Номер телефона"
                         validateTrigger={['onBlur', 'onChange']}
                         validateFirst={true}
                         rules={[
                           // {required: true, message: 'Пожалуйста, введите номер телефона'},
                           {
                             transform: (x) => x.replace(/[^+\d]/, '').replace(/[() -]/g, ''),
                             max: maxPhoneLength,
                             message: 'Максимально можно ввести 10 цифр, не включая код страны и знак "плюс" {value}'
                           },
                           {pattern: /^\+/, message: 'Первым должен идти знак "плюс" и код страны (+7 для России)'},
                           {
                             pattern: /^[+\d() -]*/,
                             message: 'В этом поле можно вводить только цифры, круглые скобки, дефисы, пробелы и код страны (+7 для России)'
                           },
                           {
                             validateTrigger: 'onBlur',
                             transform: (x) => x.replace(/[^\d+]/, '').replace(/[() -]/g, ''),
                             pattern: new RegExp('^\\+\\d{'.concat((maxPhoneLength - 1).toString(), '}$')),
                             message: 'Номер телефона должен состоять из 10 цифр (не включая знак "плюс", код страны, скобки, дефисы и пробелы)',
                           },
                           {
                             validateTrigger: 'onBlur',
                             validator: (_, value) => this.checkUserDataDuplicate(modalData.id, 'phone', value),
                             message: 'Такой номер телефона указан у другого пользователя'
                           }
                         ]}>
                <InputMask
                  key={userPhonePrefix}
                  mask={`${userPhonePrefix.replace(/9/g, '\\9')} (999) 999-99-99`}
                  maskChar="_"
                  alwaysShowMask={true}
                />
              </Form.Item>
              <Form.Item name='telegram' label="Имя пользователя в Telegram"
                         validateTrigger={['onBlur', 'onChange']}
                         validateFirst={true}
                         rules={[
                           {required: true, message: 'Пожалуйста, введите имя пользователя в Телеграмм'},
                           {pattern: /^@/, message: 'Имя должно начитаться с "собачки" (@)'},
                           {
                             pattern: /^(@|@[a-zA-Z0-9_]+)$/,
                             message: 'Имя должно содержать только символы a-z, 0-9 или знак подчеркивания'
                           },
                           {
                             validateTrigger: 'onBlur',
                             min: 6,
                             message: 'Имя должно начитаться с "собачки" (@), далее минимум 5 символов'
                           },
                           {
                             validateTrigger: 'onBlur',
                             pattern: /@[a-zA-Z0-9_]{5,}/,
                             message: 'Имя должно начитаться с "собачки" (@), далее минимум 5 символов a-z, 0-9 или знак подчеркивания'
                           },
                           {
                             validateTrigger: 'onBlur',
                             validator: (_, value) => this.checkUserDataDuplicate(modalData.id, 'telegram', value),
                             message: 'Такой телеграм указан у другого пользователя'
                           }
                         ]}>
                <Input placeholder='@nickName' autoComplete='off' name='telegram' id='telegram'/>
              </Form.Item>

            </Form>
          </Spin>
        </Modal>
      </>
    )
  }
}

export default ChangeUserInfo
