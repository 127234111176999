import api from '@services/api';
import storage from '@services/storage';
import {
  APPSTAGE_AUTH,
  AUTH_COOKIE_NAME,
  DISABLE_DOUBLE_AUTH,
  FORM_REGISTRATION,
  FORM_RESET_PASSWORD,
  FORM_VERIFY_EMAIL_REPEAT,
  ROUTE_AUTHORIZATION_IN_PROGRESS,
  ROUTE_ROOT,
} from '@src/constants';
import { getMessageFromRequest } from '@src/helpers';
import { setAppStage } from '@state/appStage';
import { closeModal, setModalContent } from '@state/modal';
import { useAppDispatch } from '@state/store';
import { setGaVisible, setUserExtraInfo } from '@state/user';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Input, message, Spin, } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { show_form_user_registration } from "@src/config";

const LoginTgForm = ({ setFormType, formRef, setAppStageData, setUserInfo, setGoogleAuth }) => {
  const [loading, setLoading] = useState(true);
  const [notFoundUser, setNotFoundUser] = useState(false);
  const [tgAuthData, setTgAuthData] = useState({});
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const query2 = useMemo(() => Object.fromEntries(decodeURI(location?.search ?? '').substring(1).split('&').map(item => item.split('='))), [location?.search]);

  /** Особое уведомление при входе на сайт - максимальный срок отображения */
  const maxDate = useMemo(() => new dayjs('2023-02-25'), []);

  /** Особое уведомление при входе на сайт - текст уведомления */
  const textMessage = useMemo(() => (
    <>
      <p>
        Уважаемые партнеры, информируем вас, что в связи с недавним обновлением в сети TRON,
        комиссии за совершаемые транзакции выросли вдвое. Ввиду чего, руководством SoulMate
        company было принято решение увеличить комиссионные сборы при вводе/выводе ваших средств
        до 4 USDT за одну транзакцию.</p>
      <br />
      <p>P.S. Все подробности об обновлении сети Tron можно найти здесь&nbsp;-&nbsp;
        <a href={'https://tronscan.org/#/proposal/83'} target='_blank' rel="noreferrer" style={{ color: '#40a9ff' }}>
          https://tronscan.org/#/proposal/83
        </a>
      </p>
    </>
  ), []);

  /** Особое уведомление при входе на сайт - функция отображения */
  const openNotification = useCallback(() => {
    dispatch(setModalContent({
      title: 'Изменение комиссии',
      content: textMessage,
      params: {
        onlyOk: true,
        onlyOkText: 'Ок',
        onOk: () => {
          dispatch(closeModal());
        }
      },
    }));
  }, [dispatch, textMessage])

  const handleRegistration = useCallback(() => {
    setFormType(FORM_REGISTRATION);
    return false;
  },[setFormType]);

  const handleResetPassword = useCallback(() => {
    setFormType(FORM_RESET_PASSWORD);
    return false;
  }, [setFormType]);

  const handleVerifyEmailRepeat = useCallback(() => {
    setFormType(FORM_VERIFY_EMAIL_REPEAT);
    return false;
  }, [setFormType]);

  const afterLogin = useCallback(async (token, userInfo) => {
    storage.set(AUTH_COOKIE_NAME, token);
    await api.setAUTHToken(token);
    await setUserInfo(userInfo);
    await setAppStageData(APPSTAGE_AUTH);
    /** Особое уведомление при входе на сайт */
    if ((new dayjs()) < maxDate) {
      setTimeout(() => openNotification(), 600);
    }
    history.push({ pathname: ROUTE_ROOT });
  }, [history, maxDate, openNotification, setAppStageData, setUserInfo]);

  const handleSubmit = useCallback(() => {
    formRef.current.validateFields().then(async (values) => {
      setLoading(true);

      const { status, data } = await api.loginTgWithEmail(values.email, values.password, tgAuthData);

      setLoading(false);

      if (status === 200 && data?.status === 20000) {
        setFormType();
        setGoogleAuth({
          visible: true,
          loginToken: data?.auth_token,
          procedure: "авторизации пользователя",
          callBackLogin: async (token, userInfo) => {
            // Предотвращение двойной 2-х-факторной аутентификации
            storage.set(DISABLE_DOUBLE_AUTH, 1);
            setTimeout(() => storage.remove(DISABLE_DOUBLE_AUTH), 5 * 1000);

            history.push({ pathname: ROUTE_AUTHORIZATION_IN_PROGRESS });
            await afterLogin(token, userInfo);
          },
        });
      } else if (status === 200 && data?.status === 0) {
        history.push({ pathname: ROUTE_AUTHORIZATION_IN_PROGRESS });
        await afterLogin(data?.token, data?.user);
      } else {
        message.error(
          getMessageFromRequest(data, 'Ошибка входа. Проверьте правильность электронной почты и пароля'),
          5
        );
      }
    });
  }, [afterLogin, formRef, history, setFormType, setGoogleAuth, tgAuthData]);

  useEffect(() => {
    async function fetchData() {
      const { status, data } = await api.loginTg(location?.search?.substring(1));
      setLoading(false);
      if (status === 200 && data?.status === 10000) {
        setNotFoundUser(true);
        setTgAuthData(data.auth_data ?? {});
      } else {
        if (status === 200 && data?.status === 20000) {
          setFormType();
          setGoogleAuth({
            visible: true,
            loginToken: data?.auth_token,
            procedure: "авторизации пользователя",
            callBackLogin: async (token, userInfo) => {
              // Предотвращение двойной 2-х-факторной аутентификации
              storage.set(DISABLE_DOUBLE_AUTH, 1);
              setTimeout(() => storage.remove(DISABLE_DOUBLE_AUTH), 5 * 1000);

              history.push({ pathname: ROUTE_AUTHORIZATION_IN_PROGRESS });
              afterLogin(token, userInfo);
            },
          });
        } else if (status === 200 && data?.status === 0) {
          history.push({ pathname: ROUTE_AUTHORIZATION_IN_PROGRESS });
          afterLogin(data?.token, data?.user);
        } else {
          message.error(
            getMessageFromRequest(data, 'Ошибка входа. Обратитесь в службу поддержки'),
            5
          );
        }
      }
    }
    location?.search?.length > 20 && fetchData();
    // eslint-disable-next-line
  }, [location?.search]);

  return (
    <Spin spinning={loading}>
      <div className="w-full flex justify-center flex-col gap-4">
        <h2>Авторизация в Телеграмм:</h2>
        <div className="w-full flex justify-center items-center flex-row gap-6">
          <div className="">{query2?.first_name} {query2?.last_name}</div>
          {query2?.photo_url && (
            <div className="w-10 h-10">
              <img
                src={unescape(query2?.photo_url)}
                alt="avatar"
                className=""
              />
            </div>)
          }
        </div>
      </div>
      {notFoundUser && (
        <>
          <hr className="!mt-6" />
          <div className="w-full flex justify-center flex-col gap-4">
            <h2>Авторизация на сайте:</h2>
            <h3>
              Ваш аккаунт Телеграмм не привязан ни к одной учетной записи на сайте.<br />
              Пожалуйста, войдите в вашу учетную записи для сохранения связи с Телеграмм-аккаунтом.
            </h3>
            <Form
              name='LoginForm'
              ref={formRef}
              layout='vertical'
              // initialValues={{ remember: true }}
              onFinish={handleSubmit}
              className="!mt-6"
              // className={styles.content}
            >
              <div className="form__content">
                <Form.Item name='email' label="Email" className="form__block"
                           validateTrigger={['onBlur', 'onChange']}
                           validateFirst={true}
                           rules={[
                             {required: true, message: 'Пожалуйста, введите адрес своей электронной почты'},
                             {validateTrigger: 'onBlur', min: 6, message: 'Минимальная длина поля - 6 символов'},
                             {max: 200, message: 'Максимальная длина поля - 100 символов'},
                             {pattern: /[@a-zA-Z0-9_.–—‐-]+/, message: 'В поле можно вводить только латинские буквы, цифры, точку, дефис и знак подчеркивания'},
                             {validateTrigger: 'onBlur', type: 'email', message: 'Пожалуйста, введите корректный адрес электронной почты'},
                           ]}>
                  <Input placeholder='nickName@example.com'/>
                </Form.Item>
                <Form.Item name='password' label="Пароль" className="form__block"
                           validateTrigger={['onBlur', 'onChange']}
                           rules={[
                             {required: true, message: 'Пожалуйста, введите пароль'},
                             {validateTrigger: 'onBlur', min: 7, message: 'Пароль может быть минимум 7 символов'},
                           ]}
                >
                  <Input.Password />
                </Form.Item>
              </div>
              <p>
                <button type='button' className="form__info" onClick={handleResetPassword}>
                  Забыл пароль
                </button>
              </p>
              <button type='submit' className="standart-btn">
                <div>Войти</div>
              </button>
              {show_form_user_registration !== 0 && (
                <span className="form__next">
                  У вас нет аккаунта?
                  &nbsp;
                  <button type='button' onClick={handleRegistration}>
                    Зарегистрируйтесь
                  </button>
                </span>
              )}
              <br />
              <span className="form__next">
                Не пришло письмо после регистрации?
                &nbsp;
                <button type='button' onClick={handleVerifyEmailRepeat}>
                  Повторить
                </button>
              </span>
            </Form>
          </div>
        </>
      )}
    </Spin>
  );
}

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  setUserInfo: setUserExtraInfo,
  setGoogleAuth: setGaVisible,
};

export default connect(null, mapDispatchToProps)(
  withTranslation()(
    LoginTgForm
  )
);
