import { ROLE_BEGINNER, SUBSCRIPTION_MAX_DAYS_VIEW } from '@src/constants';
import { subscriptionTimer } from '@src/helpers';
import withWindowResize from '@src/helpers/withWindowResize';
import { Progress } from 'antd';
import classNames from 'classnames';
import React, { Component } from 'react';

import { ReactComponent as SubscriptionIcon } from '@ui/icons/subscription.svg';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import "./styles.less"

class SubscriptionTimer extends Component {
  render() {
    const { userInfo, t, innerWidth, isVertical = false, isHorizontal = false, withInfoCard = true } = this.props;

    const days_left_view = subscriptionTimer(userInfo);
    const currentIsVertical = isVertical || (innerWidth <= 768 || innerWidth >= 1200)

    console.log({
      _f: 'SubscriptionTimer-render-22',
      res: days_left_view > SUBSCRIPTION_MAX_DAYS_VIEW,
      days_left_view,
      SUBSCRIPTION_MAX_DAYS_VIEW,
    });

    if (userInfo.role_id === ROLE_BEGINNER || days_left_view > SUBSCRIPTION_MAX_DAYS_VIEW) {
      return null;
    }

    return (
      <div className={withInfoCard ? 'info__card' : ''}>
        <div
          className={classNames(
            "subscription-timer",
            !isHorizontal && currentIsVertical
              ? 'isVertical'
              : ''
          )}
        >
          <div className="subscription">
            <div className="subscription-icon">
              <SubscriptionIcon />
            </div>
            <div className="subscription-text">
              Подписка заканчивается через:
              <br />
              <span>
                Не забудьте продлить доступ
              </span>
            </div>
          </div>
          <div className="visit">
            <div className="progress-parent">
              <div className='mirrorX'>
                <Progress
                  type="circle"
                  strokeColor={{
                    "0%": "#8A25FC",
                    "100%": "#ED4AB8",
                  }}
                  percent={days_left_view / SUBSCRIPTION_MAX_DAYS_VIEW * 100}
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeWidth={5}
                />
              </div>

              <span>{days_left_view}</span>
            </div>
            <p>{t('DAYS', { count: days_left_view })}</p>
          </div>
          <button className="standart-btn standart-btn-border buy-subscription">
            <div>Продлить</div>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withWindowResize(
    withTranslation()(
      SubscriptionTimer
    )
  )
);
