import React, { Component } from "react";
import { Button, List, message, Modal, Popconfirm, Spin } from "antd";
import api from "@services/api";

class DeleteEmptyAccount extends Component {
  state = {
    deleteEmpty: undefined,
    isDeleteEmptyModal: false,
    deleteEmptyLoading: false,
    deleteEmptyData: {
      transactionEmpty: false,
      addressEmpty: false,
      transactions: undefined,
      addresses: [],
    },
  }

  openModal = (id) => {
    this.setState({isDeleteEmptyModal: true})
    this.checkEmptyAccount(id)
  }

  closeModal = () => {
    this.setState({
      deleteEmpty: undefined,
      isDeleteEmptyModal: false,
      deleteEmptyLoading: false,
      deleteEmptyData: {
        transactionEmpty: false,
        addressEmpty: false,
        transactions: undefined,
        addresses: [],
      },
    })
  }

  checkEmptyAccount = async (userId) => {
    this.setState({deleteEmptyLoading: true, deleteEmpty: userId})

    const { status, data } = await api.checkEmptyAccount({user_id: userId})

    if (status === 200 && data?.status === 0) {
      this.setState({
        deleteEmptyData: {
          transactionEmpty: data?.data?.transaction_empty,
          addressEmpty: data?.data?.address_empty,
          transactions: data?.data?.transactions,
          addresses: data?.data?.addresses,
        },
      })
    } else {
      message.warning(data?.message ?? 'Ошибка сервера')
    }

    this.setState({deleteEmptyLoading: false})
  }

  deleteEmptyAccount = async (userId) => {
    this.setState({deleteEmptyLoading: true})

    const { status, data } = await api.deleteEmptyAccount({user_id: userId})

    if (status === 200 && data?.status === 0) {
      message.success(data?.message)
      this.setState({
        deleteEmpty: undefined,
        isDeleteEmptyModal: false,
        deleteEmptyLoading: false,
        deleteEmptyData: {
          transactionEmpty: false,
          addressEmpty: false,
          transactions: undefined,
          addresses: [],
        },
      })
      this.props.update()
    } else {
      message.warning(data?.message ?? 'Ошибка сервера')
    }

    this.setState({deleteEmptyLoading: false})
  }

  render() {
    const { deleteEmpty, isDeleteEmptyModal, deleteEmptyLoading, deleteEmptyData } = this.state
    const { id } = this.props

    return (
      <>
        <button className='w-full text-start' onClick={() => this.openModal(id)}>
          Проверить и удалить пустой аккаунт
        </button>

        <Modal
          open={isDeleteEmptyModal}
          width={'max-content'}
          destroyOnClose={true}
          onCancel={() => this.closeModal()}
          footer={(
            <div className='flex justify-center align-center'>
              <Popconfirm
                disabled={!deleteEmptyData.addressEmpty && !deleteEmptyData.transactionEmpty}
                title={'Вы действительно хотите удалить этот пустой аккаунт?'}
                okText={'Да'}
                cancelText={'Нет'}
                onConfirm={() => this.deleteEmptyAccount(deleteEmpty)}
              >
                <Button
                  disabled={!deleteEmptyData.addressEmpty && !deleteEmptyData.transactionEmpty}
                  loading={deleteEmptyLoading}
                >
                  Удалить
                </Button>
              </Popconfirm>
            </div>
          )}
        >
          <Spin spinning={deleteEmptyLoading}>
            <List className='mt-4'>
              <List.Item>
                Транзакций: {deleteEmptyData.transactions} шт.
              </List.Item>
              <List.Item>
                <List header={`Адреса (${deleteEmptyData?.addresses?.length ?? 0} шт.)`} className="">
                  {
                    deleteEmptyData?.addresses.map(
                      a => (
                        <div className="pl-4 max-h-[300px] overflow-y-auto">
                          <List.Item style={{padding: '.25em', border: "none"}}>Адрес: <br />&nbsp;&ndash;&nbsp;{a?.address}</List.Item>
                          <List.Item style={{padding: '.25em', border: "none"}}>Активен: {a?.activated ? 'Да' : 'Нет'}</List.Item>
                          <List.Item style={{padding: '.25em', border: "none"}}>Баланс: {a?.balance}</List.Item>
                          <List.Item style={{padding: '.25em', border: "none"}}>Поступивших транзакций: {a?.transactions_in}</List.Item>
                          <List.Item style={{padding: '.25em', border: "none"}}>Отравленных транзакций: {a?.transactions_out}</List.Item>
                          <List.Item style={{padding: '.25em', border: "none"}}>Итого транзакций: {a?.totalTransactionCount}</List.Item>
                          <List.Item style={{padding: '.25em', border: "none"}}>Транзакции: {a?.transactions}</List.Item>
                          <hr />
                        </div>
                      )
                    )
                  }
                </List>
              </List.Item>
            </List>
          </Spin>
        </Modal>
      </>
    )
  }
}

export default DeleteEmptyAccount