import React, { Component } from "react";
import api from "@services/api";
import { message } from "antd";

class RepeatNextFlow extends Component {

  register = async (userId) => {
    const {status, data} = await api.updateWantRepeatToNextFlow({user_id: userId});

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
      this.props.update();
    } else {
      message.warning(data?.message);
    }
  }

  cancelRegistration = async (userId) => {
    const {status, data} = await api.disableNextFlow({
      type: 'repeater',
      user_id: userId
    });

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
      this.props.update();
    } else {
      message.warning(data?.message);
    }
  }

  render() {
    const { wantRepeatNextFlow, loading, id } = this.props

    return (
      <>
        {wantRepeatNextFlow ? (
          <button className='w-full text-start' disabled={loading} onClick={() => this.cancelRegistration(id)}>
            Отмена записи на повтор обучения
          </button>
        ) : (
          <button className='w-full text-start' disabled={loading} onClick={() => this.register(id)}>
            Запись на повтор обучения
          </button>
        )}
      </>
    )
  }
}

export default RepeatNextFlow