import React, { Component } from "react";
import { Button, Form, InputNumber, message, Modal, Spin } from "antd";
import api from "@services/api";

class ChangeLevel extends Component {
  formRef = React.createRef()

  state = {
    changeLevelModalOpen: false,
    changeLevelData: undefined,
    changeLevelLoading: false
  }

  openModal = (id) => {
    this.setState({
      changeLevelModalOpen: true,
      changeLevelData: id
    })
  }

  changeLevel = async (deleteLevel = false) => {
    const userId = this.state.changeLevelData

    this.formRef.current.validateFields().then(async (value) => {
      this.setState({changeLevelLoading: true})

      const { status, data } = await api.changeLevel({user_id: userId, level: !deleteLevel ? value.level : null})

      if (status === 200 && data?.status === 0) {
        message.success(data?.message)
        await this.closeModal()
        await this.props.update()
      } else {
        message.warning(data?.message ?? 'Ошибка сервера')
      }

      this.setState({changeLevelLoading: false})
    })

  }

  closeModal = () => {
    this.setState({
      changeLevelModalOpen: false,
      changeLevelData: undefined,
    })
  }

  render() {
    const {
      changeLevelModalOpen,
      changeLevelLoading,
    } = this.state

    const { id } = this.props

    return (
      <>
        <button className='w-full text-start' onClick={() => this.openModal(id)}>
          Изменить уровень
        </button>
        <Modal
          open={changeLevelModalOpen}
          footer={(
            <div className='flex justify-center flex-wrap gap-2'>
              <Button loading={changeLevelLoading} onClick={() => this.changeLevel(true)}>Удалить ручной уровень</Button>
              <Button loading={changeLevelLoading} onClick={() => this.changeLevel()}>Сохранить ручной уровень</Button>
            </div>)}
          onCancel={() => this.closeModal()}
          width={'max-content'}
          destroyOnClose={true}
        >
          <Spin spinning={changeLevelLoading}>
            <Form ref={this.formRef}>
              <Form.Item
                label='Уровень'
                name='level'
                help={'Внимание! Минусовое значение в поле "Ручной уровень" принудительно занижает уровень, например "-5"'}>
                <InputNumber name='level'></InputNumber>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    )
  }
}

export default ChangeLevel